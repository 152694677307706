<template>
  <Popup ref="popupRef" title="轮转/暂停渠道" width="500px" :showConfirm="false" :showCancel="false" @close="resetData">
    <div>
      <div class="label">参与轮转渠道</div>
      <div class="ch-list mb12" v-if="roundChs && roundChs.length > 0">
        <div class="ch" v-for="(item, index) in roundChs" :key="index">{{item.productName}}{{item.ch}}</div>
      </div>
      <div v-else class="mb12" style="text-align: center;">-</div>
      <div class="label">参与锁定渠道</div>
      <div class="ch-list" v-if="lockChs && lockChs.length > 0">
        <div class="ch" v-for="(item, index) in lockChs" :key="index">{{item.productName}}{{item.ch}}</div>
      </div>
      <div v-else style="text-align: center;">-</div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
export default {
  components:{ 
    Popup
  },
  data() {
    return {
      roundChs: [],
      lockChs: ''
    }
  },
  methods: {
    open(option = {}) {
      this.roundChs = option.roundChs || []
      this.lockChs = option.lockChs || []
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.roundChs = []
      this.lockChs = []
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 14px;
  color: #505E5C;
  margin-bottom: 12px;
}
.ch-list {
  display: flex;
  flex-wrap: wrap;
  .ch {
    height: 34px;
    line-height: 34px;
    padding: 0 12px;
    border-radius: 17px;
    border: 1px solid #E8E8E8;
    font-size: 14px;
    margin: 0 6px 12px 0;
    color: #001A18;
  }
}
</style>